import React, { useRef, useState } from 'react';
import { InteractiveImage } from '../ InteractiveImage/InteractiveImage';
import { getRandomString } from '../../utils/getRandomString';
import styles from './Section.module.css';

const blobIds = [
  'blob0',
  'blob1',
  'blob2',
  'blob3',
  'blob4',
  'blob5',
];

export const Section = (props) => {
  const {
    title,
    subtitle,
    children,
    marginTop = 0,
    round = false,
    image,
    id = ''
  } = props;
  const [seed] = useState(getRandomString());
  const blobId = useRef(blobIds[(Math.random() * blobIds.length) << 0]);

  return (
    <>
      <InteractiveImage
        id={'scroll' + id}
        src={image}
        round={round}
        style={{ marginTop: marginTop }} />

      <div className={`${styles.container} category`} id={id}>
        <svg className={styles.blob} viewBox="0 0 1440 320">
          <path style={{ visibility: `${seed}blob0` === `${seed}${blobId.current}` ? 'visible' : 'hidden' }} d="M0,32L34.3,42.7C68.6,53,137,75,206,69.3C274.3,64,343,32,411,26.7C480,21,549,43,617,69.3C685.7,96,754,128,823,138.7C891.4,149,960,139,1029,122.7C1097.1,107,1166,85,1234,101.3C1302.9,117,1371,171,1406,197.3L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          <path style={{ visibility: `${seed}blob1` === `${seed}${blobId.current}` ? 'visible' : 'hidden' }} d="M0,64L34.3,58.7C68.6,53,137,43,206,58.7C274.3,75,343,117,411,154.7C480,192,549,224,617,234.7C685.7,245,754,235,823,229.3C891.4,224,960,224,1029,208C1097.1,192,1166,160,1234,138.7C1302.9,117,1371,107,1406,101.3L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          <path style={{ visibility: `${seed}blob2` === `${seed}${blobId.current}` ? 'visible' : 'hidden' }} d="M0,0L34.3,26.7C68.6,53,137,107,206,144C274.3,181,343,203,411,176C480,149,549,75,617,80C685.7,85,754,171,823,197.3C891.4,224,960,192,1029,202.7C1097.1,213,1166,267,1234,256C1302.9,245,1371,171,1406,133.3L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          <path style={{ visibility: `${seed}blob3` === `${seed}${blobId.current}` ? 'visible' : 'hidden' }} d="M0,320L34.3,266.7C68.6,213,137,107,206,96C274.3,85,343,171,411,186.7C480,203,549,149,617,117.3C685.7,85,754,75,823,85.3C891.4,96,960,128,1029,149.3C1097.1,171,1166,181,1234,186.7C1302.9,192,1371,192,1406,192L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          <path style={{ visibility: `${seed}blob4` === `${seed}${blobId.current}` ? 'visible' : 'hidden' }} d="M0,0L34.3,32C68.6,64,137,128,206,170.7C274.3,213,343,235,411,245.3C480,256,549,256,617,229.3C685.7,203,754,149,823,122.7C891.4,96,960,96,1029,117.3C1097.1,139,1166,181,1234,202.7C1302.9,224,1371,224,1406,224L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          <path style={{ visibility: `${seed}blob5` === `${seed}${blobId.current}` ? 'visible' : 'hidden' }} d="M0,288L34.3,277.3C68.6,267,137,245,206,213.3C274.3,181,343,139,411,106.7C480,75,549,53,617,64C685.7,75,754,117,823,160C891.4,203,960,245,1029,218.7C1097.1,192,1166,96,1234,53.3C1302.9,11,1371,21,1406,26.7L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
        </svg>

        <div className={`${styles.bodyContainer}`} >
          <div className={styles.body}>
            {
              title &&
              <p className={styles.title}>{title}</p>
            }
            {
              subtitle &&
              <p className={styles.subtitle}>{subtitle}</p>
            }
          </div>
        </div>
        {children}

        <div className={styles.endContainer}>
          <svg viewBox="0 0 1 1" className={styles.end}>
            <polygon fill="black" points="0,0 0.5,0.05 1,0" />
          </svg>
        </div>

      </div>
    </>
  );
};
